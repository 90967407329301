<template>
  <div>
    <div class="form-row">
      <NameField
        :label="$t('field.name.label')"
        placeholder=""
        v-on="$listeners"
      />
    </div>

    <div class="form-row border-t-0">
      <PhoneField
        :label="$t('field.phone.label')"
        placeholder=""
        v-on="$listeners"
      />
    </div>

    <div class="form-row">
      <EmailField
        :label="$t('field.email.label')"
        placeholder=""
        v-on="$listeners"
      />
    </div>

    <div v-if="serviceIsCorporate" class="form-row">
      <CompanyNameField
        :label="$t('field.company-name.label')"
        placeholder=""
        v-on="$listeners"
      />
    </div>

    <div class="form-row">
      <CommentsField
        :label="$t('field.comments.label')"
        :is-optional="true"
        placeholder=""
        v-on="$listeners"
      />
    </div>
  </div>
</template>

<script>
import NameField from 'chimera/all/components/form/fields/name/NameField'
import EmailField from 'chimera/all/components/form/fields/email/EmailField'
import CommentsField from 'chimera/all/components/form/fields/comments/CommentsField'
import PhoneField from 'chimera/all/components/form/fields/phone/PhoneField'
import CompanyNameField from 'chimera/all/components/form/fields/companyName/CompanyNameField'
import usesMovingServices from 'chimera/moving/mixins/usesMovingServices'

export default {
  name: 'ContactInformationFormPart',

  components: {
    CompanyNameField,
    NameField,
    EmailField,
    CommentsField,
    PhoneField,
  },

  mixins: [usesMovingServices],

  /**
   */
  mounted() {
    if (!this.serviceIsCorporate) {
      this.$store.dispatch('lead/rm', 'company-name')
    }
  },
}
</script>
